import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//Data
import { clientsList } from '@data/en/clients';

//Styles
import styles from './FeaturedClients.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Client } from './Client/Client';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const FeaturedClients = props => {
    const { title, clients = clientsList } = props;
    const controls = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    if (!clients) return null;

    const variants = {
        visible: {
            transition: {
                staggerChildren: 0.15,
            },
        },
    };

    return (
        <section className={styles.featuredClients}>
            <Container>
                {title && (
                    <FadeInWhenVisible>
                        <h2 className={`${styles.featuredClients__title} h3`}>{title}</h2>
                    </FadeInWhenVisible>
                )}

                <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                    className={styles.featuredClients__row}
                >
                    {clients.map((client, index) => {
                        return <Client key={index} path={client.logo.path} alt={client.logo.alt} />;
                    })}
                </motion.div>
            </Container>
        </section>
    );
};

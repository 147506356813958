import React from 'react';

//Styles
import styles from './QuotesSlider.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';

//Images
import ArrowRightUpBlack from '@images/icons/arrow-right-up-black.svg';

export const Slide = props => {
    const { quote: text, from, role, company, source, setCursorActive } = props;

    const paragraphs = text.map((p, index) => {
        return (
            <p className="big" key={index}>
                {/* Quotationmark if its the first paragraph */}
                {index === 0 ? (
                    <span className={styles.quoteSlider__quotationMark}>&#8220;</span>
                ) : null}

                {p}

                {/* Quotationmark if its the last paragraph */}
                {index === text.length - 1 ? (
                    <span className={styles.quoteSlider__quotationMark}>&#8221;</span>
                ) : null}
            </p>
        );
    });

    return (
        <div className={styles.quoteSlider__slide}>
            <Container>
                <div className={styles.quoteSlider__text}>{paragraphs}</div>

                <div className={styles.quoteSlider__slideInfo}>
                    <span className={styles.quoteSlider__from}>{from}, </span>
                    {role} - {company}
                    {source?.name && source?.url ? (
                        <small className={styles.quoteSlider__source}>
                            <ArrowRightUpBlack className={styles.quoteSlider__arrow} />
                            <a
                                onMouseEnter={() => setCursorActive(false)}
                                onMouseLeave={() => setCursorActive(true)}
                                href={source.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {source.name}
                            </a>
                        </small>
                    ) : null}
                </div>
            </Container>
        </div>
    );
};

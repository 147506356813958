import { blastgalaxy } from './Items/blast-galaxy';
import { otaking } from './Items/otaking';
import { heineken } from './Items/heineken';
import { rvo } from './Items/rvo';
import { lely } from './Items/lely';
import { godiva } from './Items/godiva';
import { vandijk } from './Items/van-dijk';
import { ramenKingdom } from './Items/ramen-kingdom';

export const clientsList = [
    blastgalaxy,
    otaking,
    heineken,
    rvo,
    lely,
    godiva,
    vandijk,
    ramenKingdom,
];

export const haarlemseWinkels = {
    // path: '/work/haarlemse-winkels',
    title: 'How to support the business owners of Haarlem during a global lockdown?',
    thumbnail: {
        background_color: '#c8293a',
        image: {
            path: 'projects/haarlemse-winkels/haarlemse-winkels-thumbnail.png',
            alt: 'Haarlemse winkels',
        },
    },
    live_demo: 'https://haarlemsewinkels.nl',
};

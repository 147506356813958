import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, useReducedMotion } from 'framer-motion';

//Styles
import styles from './Client.module.scss';

//Components
import Img from 'gatsby-image';

export const Client = props => {
    const { path, alt = '' } = props;
    const shouldReduceMotion = useReducedMotion();

    const data = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fixed(width: 150, quality: 100) {
                                ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    `);

    const variants = !shouldReduceMotion
        ? {
              hidden: {
                  opacity: 0,
              },
              visible: {
                  opacity: 1,
                  transition: {
                      duration: 1,
                  },
              },
          }
        : {};

    const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(path);
    });

    if (!image) return null;

    const extension = path.match(/[^\\]*\.(\w+)$/)[1];
    if (extension === 'svg' || extension === 'gif') {
        return (
            <div className={styles.client}>
                <motion.img
                    variants={variants}
                    loading="lazy"
                    className={styles.icon}
                    src={image.node.publicURL}
                    alt={alt}
                />
            </div>
        );
    }

    return (
        <motion.div variants={variants} className={styles.client}>
            <Img className={styles.icon} fixed={image.node.childImageSharp.fixed} alt={alt} />
        </motion.div>
    );
};

import React, { useState } from 'react';
import '@styles/lib/slick.scss';
import Slider from 'react-slick';

//Data
import settings from './settings';

//Styles
import styles from './QuotesSlider.module.scss';

//Components
import { Slide } from './Slide';
import { Container } from '@components/Utilities/Container/Container';
import { Cursor } from '@components/Cursor/Cursor';

export const QuotesSlider = props => {
    const { title, quotes } = props;

    const [sliderIndex, setSliderIndex] = useState(1);
    const [cursorActive, setCursorActive] = useState(false);

    const slides = quotes.map((quote, index) => {
        return (
            <Slide
                key={index}
                quote={quote.quote}
                from={quote.from}
                role={quote.role}
                company={quote.company}
                source={quote.source}
                setCursorActive={setCursorActive}
            ></Slide>
        );
    });

    const sliderChange = index => {
        setSliderIndex(index + 1);
    };

    return (
        <section
            className={`${styles.quoteSlider} ${
                slides.length === 1 ? styles.quoteSliderSingleSlide : ''
            }`}
        >
            <div className={styles.quoteSlider__wrapper}>
                {title && (
                    <Container>
                        <h2 className="h3">{title}</h2>
                    </Container>
                )}

                <div
                    role="presentation"
                    onMouseEnter={() => setCursorActive(true)}
                    onMouseLeave={() => setCursorActive(false)}
                >
                    <Slider afterChange={sliderChange} {...settings}>
                        {slides}
                    </Slider>

                    {slides.length > 1 ? (
                        <Cursor cursorActive={cursorActive} type="slider-red" />
                    ) : null}
                </div>

                <Container className={styles.quoteSlider__slideCounter}>
                    {sliderIndex} / {slides.length}
                </Container>
            </div>
        </section>
    );
};

import React from 'react';

//Data
import { data } from '@data/en/pages/work';
import { projectsList } from '@data/en/projects/';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { Hero } from '@components/Hero/Hero';
import { ProjectsList } from '@components/ProjectsList/ProjectsList';
import { FeaturedClients } from '@components/FeaturedClients/FeaturedClients';
import { QuotesSlider } from '@components/QuotesSlider/QuotesSlider';
import { Footer } from '@components/Footer/Footer';

const Work = () => {
    const { seo, path, hero, featured_clients, quotes_slider } = data;

    return (
        <>
            <SEO {...seo} path={path} />

            <Header />

            <Hero small title={hero.title} text={hero.text} button={hero.button} />

            <ProjectsList projects={projectsList} noPaddingTop={true} />

            <FeaturedClients title={featured_clients.title} />

            <QuotesSlider title={quotes_slider.title} quotes={quotes_slider.quotes} />

            <Footer />
        </>
    );
};

export default Work;

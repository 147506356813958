export const leNouTattou = {
    title: 'How to shred the tattoo shop stigma with a clients first web experience?',
    thumbnail: {
        background_color: '#91bfc0',
        image: {
            path: 'projects/le-nou-tattou/le-nou-tattou-thumbnail.png',
            alt: 'Le Nou Tattou - Art in Ink',
        },
    },
    live_demo: 'https://lenou.nl',
};
